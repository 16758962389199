@import '../theme/mixins';

$brand: 'breakburger';
$brandAssets: '/assets/#{$brand}';
@import '../theme/mixins';

@font-face {
	font-family: AppFont;
	src: url('../assets/fonts/LeagueGothic-Regular.ttf');
}


app-home {
	background: white url(#{$brandAssets}/home_mobile.png) no-repeat center center / 100% 100% !important;
	background-size: cover !important;

	@include mobile {
		background: url(#{$brandAssets}/home_mobile.png) no-repeat center center / 100% 100% !important;
	}

	ion-header {
		background: transparent;
	}

	ion-content {
		background: transparent !important;
		--background: transparent !important;
	}
}
app-menu {
	background: #f4f2ee no-repeat center top / 100% !important;

	ion-header {
		background: transparent;
	}

	ion-content {
		background: transparent !important;
		--background: transparent !important;
	}
}

$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

$app-warn: mat-palette($mat-red);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($app-theme);

:root {
	--brand: 'selforder';

	--ion-color-primary: #3b2e21;
	--ion-color-primary-rgb: 59, 46, 33;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #34281d;
	--ion-color-primary-tint: #4f4337;

	--ion-color-secondary: #8B1818;
	--ion-color-secondary-rgb: 139,24,24;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #7a1515;
	--ion-color-secondary-tint: #972f2f;

	/** tertiary **/
	--ion-color-tertiary: #f6f3eb;
	--ion-color-tertiary-rgb: 246, 243, 235;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #d8d6cf;
	--ion-color-tertiary-tint: #f7f4ed;
	/** payment-success **/
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16, 220, 96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	/** warning **/
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255, 206, 0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	/** dark **/

	--ion-color-dark: #474030;
	--ion-color-dark-rgb: 84, 80, 76;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #4a4643;
	--ion-color-dark-tint: #65625e;

	/** medium **/
	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 244, 244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	--ion-color-second: #e41375;
	--ion-color-second-rgb: 242, 183, 0;
	--ion-color-second-contrast: #ffffff;
	--ion-color-second-contrast-rgb: 255, 255, 255;
	--ion-color-second-shade: #f2b700;
	--ion-color-second-tint: #f2b700;

	--ion-color-gray-light: #f5f5f5;
	--ion-color-gray-dark: #d4d4d4;
	--ion-color-gray-darkest: #1f1b1c;

	--ion-color-black: #474030;
	--ion-color-label: #66605a;

	--ion-default-font: AppFont, serif !important;
	--ion-text-color: var(--ion-color-black);
	--quantity-selector-border-width: 1px;
	--quantity-selector-border-color: var(--ion-color-primary);
	--quantity-selector-height: 40px;
	--quantity-selector-background: white;
	--quantity-selector-text-color: var(--ion-color-primary);

	--background-default: var(--ion-color-tertiary);
  --border-radius-default: 9px;
}

.ion-color-main-color {
	--ion-color-base: var(--ion-color-main);
	--ion-color-base-rgb: var(--ion-color-main-rgb);
	--ion-color-contrast: var(--ion-color-main-contrast);
	--ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
	--ion-color-shade: var(--ion-color-main-shade);
	--ion-color-tint: var(--ion-color-main-tint);
}

.ion-color-second-color {
	--ion-color-base: var(--ion-color-second);
	--ion-color-base-rgb: var(--ion-color-second-rgb);
	--ion-color-contrast: var(--ion-color-second-contrast);
	--ion-color-contrast-rgb: var(--ion-color-second-contrast-rgb);
	--ion-color-shade: var(--ion-color-second-shade);
	--ion-color-tint: var(--ion-color-second-tint);
}

.ion-color-black {
	--ion-color-base: var(--ion-color-black);
	--ion-color-base-rgb: var(--ion-color-black);
	--ion-color-contrast: var(--ion-color-main-contrast);
	--ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
	--ion-color-shade: var(--ion-color-main-shade);
	--ion-color-tint: var(--ion-color-main-tint);
}

html,
body {
	height: 100%;
	font-family: AppFont, 'Helvetica Neue', sans-serif;
	color: var(--ion-color-black);
}

body {
	margin: 0;
}

h1 {
	font-family: AppFont, Helvetica, sans-serif;
}
ion-label {
	color: var(--ion-color-label);
}

p, span {
  margin: 0;
}

.ion-page,
ion-footer,
ion-content {
	background: var(--ion-color-tertiary);
	--background: var(--ion-color-tertiary);
}
app-menu-modal {
	background: var(--ion-color-secondary) url(#{$brandAssets}/activity_burger_background.png) no-repeat right bottom /
		100% !important;

	ion-header {
		background: transparent;
	}

	ion-content {
		background: transparent !important;
		--background: transparent !important;
	}
}
